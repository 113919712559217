export default [
  {
    handle: "G8dzgKGozPQj3fwJAeDefb2Wjao9M75bEo1jegTTKRAN",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=G8dzgKGozPQj3fwJAeDefb2Wjao9M75bEo1jegTTKRAN&amount=200000000&index=0&proof=6u5hbAheNQakxVrxTRgByFnUdwoLbmoZJ42jz7YDmYSN,GbBUW9HtZzsnkTZxeR5g4tHNRK25zBa89aXFqWnpoCP7,GmEEoQy8h6bCzFBtSBzPUanT3vniBwJ4u92SN9mrs9fK,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "2BHY7pRRRgvFnfjjiHEh6fPbkMy4igDoQ5Y8P1pvxhtB",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=2BHY7pRRRgvFnfjjiHEh6fPbkMy4igDoQ5Y8P1pvxhtB&amount=200000000&index=1&proof=Ezh76resegxUBHe2LwujYya5stma4BZ7XCrwGFr5F8vG,GbBUW9HtZzsnkTZxeR5g4tHNRK25zBa89aXFqWnpoCP7,GmEEoQy8h6bCzFBtSBzPUanT3vniBwJ4u92SN9mrs9fK,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "2fEWy8EwuXneH2J7M1v8qCbh5W1ntEaS3Cu5p3GAWLPJ",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=2fEWy8EwuXneH2J7M1v8qCbh5W1ntEaS3Cu5p3GAWLPJ&amount=200000000&index=2&proof=4xc5nPZi2y2aBB6sK5UFqJWhAP2xgahZVr5k1sWRkQqW,7VgDD1sWYbFB4XDS8kLNoM5wGmAZx7jj74e4HLfBRWSG,GmEEoQy8h6bCzFBtSBzPUanT3vniBwJ4u92SN9mrs9fK,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "2kFL6crwKU4NVVkizXWLy4Dd9HpXEhaXNsdC1j3BTcUP",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=2kFL6crwKU4NVVkizXWLy4Dd9HpXEhaXNsdC1j3BTcUP&amount=200000000&index=3&proof=6J58Btx5fhxAtiZGWJLKN7tZ3jHfq2BHTv7iMK8Lw3GD,7VgDD1sWYbFB4XDS8kLNoM5wGmAZx7jj74e4HLfBRWSG,GmEEoQy8h6bCzFBtSBzPUanT3vniBwJ4u92SN9mrs9fK,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "NrdPMCakM8kJpve6BNDQx7BjNaJ37J3utqFm9eM1b8y",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=NrdPMCakM8kJpve6BNDQx7BjNaJ37J3utqFm9eM1b8y&amount=200000000&index=4&proof=G8AY6fNFvRGM6UPgcqf6iFxQkS1dXfpHmKAtWGRiAitk,7KxiDN9h58uPUjoJXK8Z6q2hRnRnu3cS5UV1PVTgnmFr,56CWWFksSm9gKgVm7wht1YfdNSpjdExRckxWDvNC7eBY,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "4q9CgLjHCrtNBXCnVczPnT3rZ7Ei8FAj9xVxKovQ5XDu",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=4q9CgLjHCrtNBXCnVczPnT3rZ7Ei8FAj9xVxKovQ5XDu&amount=200000000&index=5&proof=ANYna9v1ff8y6JAXasYL1oCUzPndiuewq63DjZamLva8,7KxiDN9h58uPUjoJXK8Z6q2hRnRnu3cS5UV1PVTgnmFr,56CWWFksSm9gKgVm7wht1YfdNSpjdExRckxWDvNC7eBY,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "23xrRTYdTmosbC8Z3oeG4C2522j2jqhqTCHR5V7fNexb",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=23xrRTYdTmosbC8Z3oeG4C2522j2jqhqTCHR5V7fNexb&amount=200000000&index=6&proof=HSb8WJvD3mJUQ5zsvSxSqQsTddNU53UQ9scuXbvcPDzP,DnVudfmkctsBK2kFwyFWof3WVfkmhEv6FsBzQJ8EkjMb,56CWWFksSm9gKgVm7wht1YfdNSpjdExRckxWDvNC7eBY,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "AFHXL4fbXSPDtV8ZfpiYvQDz2MZBufpkbC57brdVWsJL",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=AFHXL4fbXSPDtV8ZfpiYvQDz2MZBufpkbC57brdVWsJL&amount=200000000&index=7&proof=5Hsyjf8SXzdfZNTHpn5fXa1jC96LMZ3Sm8EPfvrQ6huP,DnVudfmkctsBK2kFwyFWof3WVfkmhEv6FsBzQJ8EkjMb,56CWWFksSm9gKgVm7wht1YfdNSpjdExRckxWDvNC7eBY,4VvTPasX7ge69q6DehRXm66yBWJt9MASukvDTXoVCmdn&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "vyjs4u4DGogvYsssfqxRoK4KjiGaqcJrQng3ujtU97s",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=vyjs4u4DGogvYsssfqxRoK4KjiGaqcJrQng3ujtU97s&amount=200000000&index=8&proof=74nTpKEkkSa8A3j4c7MjSyA8napbZq4qqMTWHQzvtGma,G3WwAWNqtZeqeXSpdiUwHAdDfAaDVmnrEjvSj667zhPL,9HaQLgZgGYbEyNerjSGDnYAVk8pUFHiqr21ZwvhB1Dxr&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "DThGyTiXKEnkUgMwom9FCYuD4ikZdqjhnqxZ4gv8dpHF",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=DThGyTiXKEnkUgMwom9FCYuD4ikZdqjhnqxZ4gv8dpHF&amount=200000000&index=9&proof=DFTQ7Dp44GPB4AQxdBQhkKzdgpr38dh7dDs1Uq2BEZQQ,G3WwAWNqtZeqeXSpdiUwHAdDfAaDVmnrEjvSj667zhPL,9HaQLgZgGYbEyNerjSGDnYAVk8pUFHiqr21ZwvhB1Dxr&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "Avda5N4gyC8iTsnSnkmjLFEx5URtvWNRpRMgCdCvdH4p",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=Avda5N4gyC8iTsnSnkmjLFEx5URtvWNRpRMgCdCvdH4p&amount=200000000&index=10&proof=8q6jvZxiZ6DDBvxpY1YF47giTsqsqVyXJz4aMpsbtg8Z,Hkj9WJ2yxjR3cRHUn7qSoe5GPYbEXKRrPHGzY4c6PAPg,9HaQLgZgGYbEyNerjSGDnYAVk8pUFHiqr21ZwvhB1Dxr&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
  {
    handle: "ABXQRJRD7wFHkmqfrEqTEAQpNeUu78Sq2jtXi2twzkHQ",
    amount: 200000000,
    url: "https://gumdrop.metaplex.com//claim?distributor=AKUEmZDidWU2YqMSJwoZ6RgJ52sBsAGGt6tHua24KET1&method=wallets&handle=ABXQRJRD7wFHkmqfrEqTEAQpNeUu78Sq2jtXi2twzkHQ&amount=200000000&index=11&proof=93aLzx8F8fvpHLUDTaMjaUTkD1tEN5QVSmU2KjnWzM1e,Hkj9WJ2yxjR3cRHUn7qSoe5GPYbEXKRrPHGzY4c6PAPg,9HaQLgZgGYbEyNerjSGDnYAVk8pUFHiqr21ZwvhB1Dxr&pin=NA&tokenAcc=EafAP7fGY1UawCwoxKB6B6qRR1rGjk8zqCYK5WQB6FNa",
  },
];
