import staticCollectionArray from "../data/meCollData";
import { useState } from "react";

function myFunction() {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  ul = document.getElementById("myUL");
  li = ul.getElementsByTagName("");
  for (i = 0; i < li.length; i++) {
    a = li[i].getElementsByTagName("option")[0];
    txtValue = a.textContent || a.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = "";
    } else {
      li[i].style.display = "none";
    }
  }
}

export default () => {
  let defaultString = "";
  let updateDateString = "9/23/22";
  const [searchString, setSearchString] = useState(defaultString);
  const [selectedColl, setSelectedColl] = useState(defaultString);
  return (
    <div>
      <section className="section">
        <div className="row align-items-top">
          <div className="col-lg-9">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Select From All Collections (Beta Testing)
                </h5>
                <div className="row mb-3">
                  <div className="col-sm-10">
                    <p className="card-text">
                      There are {staticCollectionArray.length} collections in
                      our latest snapshot from {updateDateString}. <br />
                      The collections are listed in this format:{" "}
                      <b>Collection Name (Collection Symbol)</b>
                    </p>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue="Select a collection"
                      id="myUL"
                    >
                      {staticCollectionArray.map((_, index) => (
                        <option>
                          {staticCollectionArray[index]?.name} (
                          {staticCollectionArray[index]?.symbol})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
