import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import gumdropClaimUrls from "../data/gumdropClaimUrls";
import { useQuery } from "@tanstack/react-query";

const ClaimYourRewards = () => {
  let userHandle = "";
  let userAmount = 0;
  let userUrl = "";
  let userVerified = false;

  function useSearchQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useSearchQuery();
  userHandle = query.get("userHandle");

  // validate/verify address
  for (let x of gumdropClaimUrls) {
    if (userHandle == x.handle) {
      userAmount = x.amount;
      userUrl = x.url;
      userVerified = true;
    }
  }
  if (userVerified) {
    return (
      <section className="section">
        <div className="row align-items-top">
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Success! Wallet Address Verified</h5>
                <h6>
                  Public Key:{" "}
                  <a
                    href={"https://solscan.io/account/" + userHandle}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>{userHandle}</b>
                  </a>
                </h6>
                <br /><br />
                <p>
                  You are eligible to claim <b>{userAmount / 1e6} KING</b> on
                  Gumdrop! You can only claim once per reward cycle.
                </p>
                <a href={userUrl} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="btn btn-primary">
                    Claim Your Reward
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <a href={userUrl} target="_blank" rel="noopener noreferrer">
              <div className="card">
                <img
                  src="assets/img/Sylas_Scene.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Ape King only shares his loot with loyal followers.
                  </h5>
                  <p className="card-text"></p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    );
  } else {
    //console.log("No rewards today.");
    return (
      <section className="section">
        <div className="row align-items-top">
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">No Rewards Today</h5>
                <h6>
                  Public Key:{" "}
                  <a
                    href={"https://solscan.io/account/" + userHandle}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>{userHandle}</b>
                  </a>
                </h6>
                <br />
                <p>
                  This wallet address does not appear on our active Gumdrop
                  distribution list. Check back again soon!
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4"></div>
        </div>
      </section>
    );
  }
};

export default ClaimYourRewards;
