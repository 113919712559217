import React from "react";
import SearchCollections from "../components/SearchCollections";

const AllCollections = () => {
  return (
    <div style={{ padding: "1rem" }}>
      <SearchCollections />
    </div>
  );
};

export default AllCollections;
