const Contact = () => {
  return (
    <div style={{ padding: "1rem" }}>
      <section className="section contact">
        <div className="row align-items-top">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src="assets/img/pixelapes_logo.jpg"
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">Contact Ape King</h5>
                    <p className="card-text">
                      Welcome to the NFT Tracker App! Feel free to reach out and
                      connect.
                      <br />
                      <br />
                      If you'd like to see your collection added, please send us
                      a message on Discord.
                      <br />
                      <br />
                      Otherwise, if you have any questions or feedback, we are
                      here to answer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-lg-4">
                <div className="info-box card">
                  <a href="https://pixelapes.xyz/" target={"_blank"}>
                    <i className="bi bi-globe2"></i>
                    <h3>Website</h3>
                    <p>Pixelapes.xyz</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  <a
                    href="https://discord.com/invite/9ES7UszWTX"
                    target={"_blank"}
                  >
                    <i className="bi bi-discord"></i>
                    <h3>Discord</h3>
                    <p>Click here to join PixelApes on Discord</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  <a href="https://twitter.com/PixelApes" target={"_blank"}>
                    <i className="bi bi-twitter"></i>
                    <h3>Twitter</h3>
                    <p>Click here to follow PixelApes on Twitter</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
