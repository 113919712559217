import AllTokenPairs from "../components/AllTokenPairsLoad";

function TokenPairs(props) {
  /**
  let array = props.data.sort((a, b) =>
    a.name.trim() > b.name.trim() ? 1 : b.name.trim() > a.name.trim() ? -1 : 0
  );
   */
  return (
    <div style={{ padding: "1rem" }}>
      <AllTokenPairs />
    </div>
  );
}

export default TokenPairs;
