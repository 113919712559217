import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const WatchlistSheetsDisplay = () => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <input
        autoFocus
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <button type="button" className="btn btn-danger" onClick={onClear}>
        X
      </button>
    </>
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const {
    data: tokenData,
    isLoading: tokenIsLoading,
    isError: tokenIsError,
  } = useQuery(["tokens"], async () => {
    const res = await axios.get(
      "https://tranquil-mesa-04927.herokuapp.com/https://api.raydium.io/v2/main/pairs"
    );
    return res.data;
  });

  // let array = tokenData?.sort((a, b) => (a.name.trim() > b.name.trim()) ? 1 : ((b.name.trim() > a.name.trim()) ? -1 : 0));

  function filterTokens(input) {
    return input.name.toLowerCase().includes(filterText.toLowerCase());
  }

  if (tokenIsError) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Error Warning</h5>

          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
  }

  if (tokenIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Watchlist Stats...</h5>

          <div
            className="alert alert-primary alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-star me-1"></i>
            Thanks for your patience—loading SOL price information!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div>
        <LoadROIdataTable
          tokenData={tokenData}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          filterFunction={filterTokens}
        />
      </div>
    );
};

export const LoadROIdataTable = (props) => {
  // we are given props.tokenData
  //const [tableData, setTableData] = useState(startingData);
  let tokenPairs = props.tokenData;

  /**
   * deprecated in favor of birdeyeSolPriceData.data.value
   * */
  let solPrice = 0;
  let solTokenAmountPc = 0;
  let solLiquidity = 0;
  let solLiquidityHistData = 0;
  let solTokenPriceHistData = 0;
  for (let y of tokenPairs) {
    if (y.name == "SOL-USDC" && y.tokenAmountPc > 10000) {
      // there is a duplicate token with low liquidity
      solPrice = y.price;
      solTokenAmountPc = y.tokenAmountPc;
      solLiquidity = y.liquidity;
      //console.log(y);
      //setPriceSol(y.price);
    }
  }

  function StakingIcon(props) {
    const blurb = props.row.stakingBlurb;
    if (blurb) {
      return (
        <>
          <div style={{ display: "inline-block" }} title={blurb}>
            <div className="icon">
              <i
                className="bi bi-cloud-arrow-up-fill"
                data-bs-toggle="modal"
                data-bs-target={"#addFavoriteModal" + props.row.id}
              ></i>
              <div className="label"></div>
            </div>
          </div>
          <div className="card-text" style={{ color: "black" }}>
            <div
              className="modal fade"
              id={"addFavoriteModal" + props.row.id}
              tabIndex="-1"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Staking Level Details: <br />
                      {props.row.name}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <StakingModalBlurb row={props.row} />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <a
                      href={`/collection?symbol=${props.row.symbol}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button" className="btn btn-primary">
                        View Collection Details
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return "";
  }

  function StakingModalBlurb(props) {
    const blurbHtml = props.row.stakingBlurbHtml;
    if (blurbHtml) {
      return <>{blurbHtml}</>;
    }
    return "";
  }

  const {
    data: histDataBData,
    isLoading: histDataBIsLoading,
    isError: histDataBIsError,
  } = useQuery(["histDataB"], async () => {
    const res = await axios.get(
      "https://script.google.com/macros/s/AKfycbxiRlzcK3crVmyd0lR28_sf9ahNlIDbS6oaTMUCDP1V91ARZdqdQhITrOEiPjSX7e3L-w/exec?path=histDataB"
    );
    return res.data;
  });

  const {
    data: birdeyeSolPriceData,
    isLoading: birdeyeSolPriceIsLoading,
    isError: birdeyeSolPriceIsError,
  } = useQuery(["birdeyeSolPrice"], async () => {
    const headers = {
      "X-API-KEY": "53bf2be7099141cf9500e6f2844f0882",
    };
  
    const res = await axios.get(
      "https://public-api.birdeye.so/defi/price?address=So11111111111111111111111111111111111111112",
      { headers }
    );
    return res.data;
  });

  if (histDataBIsError || birdeyeSolPriceIsError) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Error Warning</h5>

          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
  }

  if (histDataBIsLoading || birdeyeSolPriceIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Watchlist Stats...</h5>

          <div
            className="alert alert-primary alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-star me-1"></i>
            Thanks for your patience—loading King's Watchlist data!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    for (let z of histDataBData) {
      if (z.name == "SOL-USDC" && z.tokenAmountPc > 10000) {
        solLiquidityHistData = 1 * z.liquidity.toFixed(2);
        solTokenPriceHistData = 1 * z.price.toFixed(4);
      }
    }

    const SolLiquidityComponent = (props) => {
      let percentChange = (
        100 *
        ((props.liquidity - props.liquidityHistory) / props.liquidityHistory)
      ).toFixed(2);
      let totalChange =
        1 * (props.liquidity - props.liquidityHistory).toFixed(2);
      if (percentChange < 0) {
        return (
          <h6 className="solPriceHeader" style={{ color: "#dc3545" }}>
            <b>
              {percentChange}% <i className="bi bi-arrow-down"></i> {totalChange}
            </b>
          </h6>
        );
      } else {
        return (
          <h6 className="solPriceHeader" style={{ color: "#198754" }}>
            <b>
              {percentChange}% <i className="bi bi-arrow-up"></i> {totalChange}
            </b>
          </h6>
        );
      }
    };

    const SolPriceComponent = (props) => {
      let percentChange = (
        100 *
        ((props.price - props.priceHistory) / props.priceHistory)
      ).toFixed(2);
      let totalChange = 1 * (props.price - props.priceHistory).toFixed(2);
      if (percentChange < 0) {
        return (
          <h5 className="solPriceHeader" style={{ color: "#dc3545" }}>
            <b>
              {percentChange}% <i className="bi bi-arrow-down"></i> {totalChange}
            </b>
          </h5>
        );
      } else {
        return (
          <h5 className="solPriceHeader" style={{ color: "#198754" }}>
            <b>
              {percentChange}% <i className="bi bi-arrow-up"></i> {totalChange}
            </b>
          </h5>
        );
      }
    };

    return (
      <div>
        <section class="section">
          <div class="row align-items-top">
            <div class="col-lg-6">
              <a
                href="https://pixelapes.xyz/rewards"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-5">
                      <img
                        src="assets/img/Sylas_Scene.jpg"
                        class="img-fluid rounded-start"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-7">
                      <div class="card-body">
                        <h5 class="card-title">
                          Claim your share of the King’s Treasure.
                        </h5>
                        <p class="card-text">
                          Check back daily to see if Ape King has bestowed his
                          Treasures upon you. Only loyal followers will receive
                          the King’s Rewards.
                          <br />
                        </p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4 d-flex align-items-stretch p-0">
              <div className="card mb-3 w-100">
                <div className="row g-0">
                  <div className="col-md-4">
                  <br />
                    <img height='230' src="https://alternative.me/crypto/fear-and-greed-index.png" alt="Latest Crypto Fear & Greed Index" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h4 className="solPriceHeader">
                        <br />
                        <br />
                        <br />
                        Price of SOL:{" "}
                        <b>${1 * birdeyeSolPriceData.data.value.toFixed(2)}</b>
                      </h4>
                      <br />
                      <p
                        className="card-text"
                        style={{ fontSize: "small", textAlign: "right" }}
                      >
                        <i>Live price data from BirdEye.so</i>
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="row align-items-top" style={{ padding: "1rem" }}>
            <div className="col-lg-12">
              <div className="card mb-12" style={{ padding: "2rem" }}>
              <h3>King's Watchlist</h3>
              <br />
              <iframe
                style={{marginLeft: '4%'}}
                src="https://docs.google.com/spreadsheets/d/1mH_ORxJNPBsNbb1UBDUfvDUQgps8ZNjBzpTCHOYc1fA/gviz/tq?tqx=out:html&tq&gid=0"
                width="100%"
                height="1300"
              ></iframe>
              </div>
            </div>
          </div>
        </section>
        <br />
      </div>
    );
  }
};

export default WatchlistSheetsDisplay;
