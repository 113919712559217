import React from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Collections from "./routes/Collections";
import MyFavorites from "./routes/MyFavorites";
import TokenPairs from "./routes/TokenPairs";

import FAQ from "./routes/FAQ";
import Contact from "./routes/Contact";
import Collection from "./routes/Collection";
import AllCollections from "./routes/AllCollections";
import ClaimYourRewards from "./components/ClaimYourRewards";

import KingsAlphaChallenge from "./components/AlphaChallenge";
import KingsNFTFund from "./components/NFTFund";
import ROIsheetsDisplay from "./components/ROIsheets";
import WatchlistSheetsDisplay from "./components/WatchlistSheets";
// Routes to pages for beta testing Phantom wallet connection:
//import ConnectYourWallet from "./components/ConnectYourWallet";
//import YellowFlags from "./routes/YellowFlags";

function App() {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });

  let sampleCollections = [
    {
      symbol: "rudecats",
      name: "Rude Cats ",
      description:
        "The baddest cats roaming arround solana blockchain! Get your Rude Cat!",
      image:
        "https://creator-hub-prod.s3.us-east-2.amazonaws.com/rudecats_pfp_1657584395352.png",
      twitter: "https://www.twitter.com/rude_cats",
      discord: "https://www.discord.gg/JF3G8jJNeu",
      website:
        "https://www.launchmynft.io/collections/3iSibS9SnCu3ANVnQ6tBxTiJ4U3uNW3DkRRJdKNa4am8/VmjsM9foYEUTcIzmM2gg",
      categories: ["pfps", "art"],
    },
  ];

  let sampleCollectionStats = [
    {
      symbol: "TEST DATA - Magic Eden Stats not pulling yet.",
      floorPrice: -1.0,
      listedCount: -1.0,
      volumeAll: -1.0,
    },
  ];

  let sampleTokens = [
    {
      name: "Loading...",
      ammId: "Loading...",
      lpMint: "Loading...",
      market: "Loading...",
      liquidity: -1.0,
      volume24h: -1.0,
      volume24hQuote: -1.0,
      fee24h: -1.0,
      fee24hQuote: -1.0,
      volume7d: -1.0,
      volume7dQuote: -1.0,
      fee7d: -1.0,
      fee7dQuote: -1.0,
      volume30d: -1.0,
      volume30dQuote: -1.0,
      fee30d: -1.0,
      fee30dQuote: -1.0,
      price: -1.0,
      lpPrice: -1.0,
      tokenAmountCoin: -1.0,
      tokenAmountPc: -1.0,
      tokenAmountLp: -1.0,
      apr24h: -1.0,
      apr7d: -1.0,
      apr30d: -1.0,
    },
  ];

  // this URL loads all Raydium tokens.
  let raydiumURL =
    "https://tranquil-mesa-04927.herokuapp.com/https://api.raydium.io/v2/main/pairs";
  // this URL loads the most recent 500 collections.
  // I need to implement a looping call to load each set of 500.
  let magicEdenURL =
    "https://tranquil-mesa-04927.herokuapp.com/https://api-mainnet.magiceden.dev/v2/collections?offset=500&limit=500";
  // here is a collection stats URL hard-coded with pixelapes for testing.
  let meStatsURL =
    "https://tranquil-mesa-04927.herokuapp.com/https://api-mainnet.magiceden.dev/v2/collections/pixelapes/stats";

  //const [tokenPairs, setTokenPairs] = React.useState(sampleTokens);
  const [collections, setCollections] = React.useState(sampleCollections);
  const [collectionStats, setCollectionStats] = React.useState(
    sampleCollectionStats
  );

  /**
  React.useEffect(() => {
    async function getRaydiumData() {
      await axios.get(raydiumURL).then((response) => {
        setTokenPairs(response.data);
      });
    }
    getRaydiumData();
  }, []);
     */

  React.useEffect(() => {
    async function getMagicEdenData() {
      await axios.get(magicEdenURL).then((response) => {
        setCollections(response.data);
      });
    }
    getMagicEdenData();
  }, []);

  React.useEffect(() => {
    async function getMEstatsData() {
      await axios.get(meStatsURL).then((response) => {
        setCollectionStats(Array(response.data));
      });
    }
    getMEstatsData();
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<KingsNFTFund />} />
            <Route
              path="myfavorites"
              element={
                <MyFavorites
                  //tokenData={tokenPairs}
                  collData={collections}
                  collStats={collectionStats}
                  setCollStats={setCollectionStats}
                />
              }
            />
            <Route
              path="kingsroiscreen"
              element={
                <ROIsheetsDisplay
                  //tokenPairs={tokenPairs}
                  collections={collections}
                  collectionStats={collectionStats}
                  setCollStats={setCollectionStats}
                />
              }
            />
            <Route
              path="kingswatchlist"
              element={
                <WatchlistSheetsDisplay
                  //tokenPairs={tokenPairs}
                  collections={collections}
                  collectionStats={collectionStats}
                  setCollStats={setCollectionStats}
                />
              }
            />
            <Route
              path="kingsalphachallenge"
              element={
                <KingsAlphaChallenge
                //tokenPairs={tokenPairs}
                />
              }
            />
            <Route
              path="kingsroyalfund"
              element={
                <KingsNFTFund
                //tokenPairs={tokenPairs}
                />
              }
            />
            <Route
              path="kingsnftfund"
              element={
                <KingsNFTFund
                //tokenPairs={tokenPairs}
                />
              }
            />
            <Route path="claimyourrewards" element={<ClaimYourRewards />} />
            <Route
              path="collections"
              element={
                <Collections
                  data={collections}
                  stats={collectionStats}
                  setStats={setCollectionStats}
                />
              }
            />
            <Route path="collection" element={<Collection />} />
            <Route path="allcollections" element={<AllCollections />} />
            <Route
              path="tokenpairs"
              element={
                <TokenPairs
                //tokenData={tokenPairs}
                />
              }
            />
            <Route path="faq" element={<FAQ />} />
            <Route path="contact" element={<Contact />} />
            <Route
              path="*"
              element={
                <div style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
