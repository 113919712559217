import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { useState } from "react";
import { useLocalStorage } from "../helpers/useLocalStorage";

let defaultOffset = 0;
let defaultLimit = 20;
let defaultSymbol = "pixelapes";
let defaultString = "";

export const MagicEdenCollections = () => {
  const [favList, setFavList] = useLocalStorage("favList2", []);

  const handleFavorite = (symbol) => {
    if (!favList.find((e) => e == symbol)) setFavList([...favList, symbol]);
  };

  const deleteFavorite = (symbol) => {
    setFavList(favList.filter((fav) => fav !== symbol));
  };

  const editSearchParams = () => {
    let newLimit = document.getElementById("inputLimit").value;
    let newOffset = document.getElementById("inputOffset").value;
    // constraints from the Magic Eden API parameters
    // --limit should be within [1, 500]
    // --offset and limit must be a multiple of 20
    // --offset must be a multiple of the limit
    if (newLimit >= 1 && newLimit <= 500 && newLimit % 20 == 0)
      setLimit(newLimit);
    if (newOffset >= 0 && newOffset % 20 == 0 && newOffset % limit == 0)
      setOffset(newOffset);
  };

  const findSymbol = () => {
    let newSymbol = document.getElementById("collSymbol").value;
    if (newSymbol) setSearchSymbol(newSymbol);
  };

  const findString = () => {
    let newString = document.getElementById("myInput").value;
    if (newString) setSearchString(newString);
  };

  function myFunction() {
    findString();
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("option")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  const [offset, setOffset] = useState(defaultOffset);
  const [limit, setLimit] = useState(defaultLimit);
  const [searchSymbol, setSearchSymbol] = useState(defaultSymbol);
  const [searchString, setSearchString] = useState(defaultString);
  const [selectedColl, setselectedColl] = useState(defaultString);

  let magicEdenURL = `https://tranquil-mesa-04927.herokuapp.com/https://api-mainnet.magiceden.dev/v2/collections?offset=${offset}&limit=${limit}`;
  let searchURL = `/collection?symbol=${searchSymbol}`;

  const {
    data: meCollData,
    isLoading: meCollIsLoading,
    isError: meCollIsError,
    refetch,
  } = useQuery(["meColl"], async () => {
    const res = await Axios.get(magicEdenURL);
    //console.log(res.data);
    return res.data;
  });

  if (meCollIsError) {
    return (
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Error Warning</h5>

          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i class="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
  }

  if (meCollIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Collections...</h5>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading Collections...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const columns = [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Price (USD)",
        selector: (row) => row.price,
        sortable: true,
      },
      {
        name: "Liquidity",
        selector: (row) => row.liquidity,
        sortable: true,
      },
      {
        name: "TokenAmountPc",
        selector: (row) => row.tokenAmountPc,
        sortable: true,
      },
    ];
    return (
      <div>
        <section className="section">
          <div className="row align-items-top">
            <div className="col-lg-9">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-10">
                    <div className="card-body">
                      <br />
                      <p>
                        {" "}
                        The {limit} most recently listed collections are shown
                        below!{" "}
                      </p>
                      <p> There are {offset} skipped collections. </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {meCollData.map((_, index) => (
                <div className="card mb-3">
                  <div className="row g-0">
                    <div className="col-md-2">
                      <img
                        src={meCollData[index]?.image}
                        className="img-thumbnail"
                        alt="..."
                      />
                    </div>
                    <div className="col-md-10">
                      <div className="card-body">
                        <h5 className="card-title">
                          {meCollData[index]?.name}
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          <i>{meCollData[index]?.symbol}</i>
                        </h6>
                        <br />
                        <p className="card-text">
                          {meCollData[index]?.description}
                        </p>
                        <br />
                        <a
                          href={
                            "https://magiceden.io/marketplace/" +
                            meCollData[index]?.symbol
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="card-link"
                        >
                          Magic Eden
                        </a>
                        <a
                          href={meCollData[index]?.discord}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="card-link"
                        >
                          Discord
                        </a>
                        <a
                          href={meCollData[index]?.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="card-link"
                        >
                          Twitter
                        </a>
                        <a
                          href={meCollData[index]?.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="card-link"
                        >
                          Website
                        </a>
                        <br />
                        <br />
                        <div className="card-text">
                          <a
                            href={
                              "/collection?symbol=" + meCollData[index]?.symbol
                            }
                            className=" btn btn-primary"
                          >
                            View Collection Details
                          </a>{" "}
                          &ensp;{" "}
                          <button
                            type="button"
                            className="btn btn-success"
                            data-bs-toggle="modal"
                            data-bs-target="#addFavoriteModal"
                            onClick={() =>
                              handleFavorite(meCollData[index]?.symbol)
                            }
                          >
                            {" "}
                            Add Favorite{" "}
                          </button>{" "}
                          &ensp;{" "}
                          <button
                            type="button"
                            className="btn btn-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#removeFavoriteModal"
                            onClick={() =>
                              deleteFavorite(meCollData[index]?.symbol)
                            }
                          >
                            {" "}
                            Remove Favorite{" "}
                          </button>{" "}
                          &ensp;
                          <div
                            className="modal fade"
                            id="addFavoriteModal"
                            tabIndex="-1"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Added to your favorites!
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  {" "}
                                  You can now view this collection in your
                                  favorites.{" "}
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <a href="/myfavorites">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      href="/myfavorites"
                                    >
                                      View My Favorites
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="modal fade"
                            id="removeFavoriteModal"
                            tabIndex="-1"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Removed from your favorites!
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  {" "}
                                  You can view your saved collections or keep
                                  browsing.{" "}
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <a href="/myfavorites">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      href="/myfavorites"
                                    >
                                      View My Favorites
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Edit Search Parameters</h5>
                  <p className="card-text">
                    {" "}
                    You can display up to 500 collections as the Max Limit. This
                    number must be a multiple of 20.
                  </p>
                  <b>Limit # of Collections: </b>
                  <input
                    id="inputLimit"
                    defaultValue={limit}
                    onChange={editSearchParams}
                  ></input>
                  <br />
                  <br />
                  <p className="card-text">
                    {" "}
                    Offset is the number of collections to skip. The most recent
                    listings are listed first! This number must be a multiple of
                    20 and also a multiple of the limit.
                  </p>
                  <b>Offset # of Collections: </b>
                  <input
                    id="inputOffset"
                    defaultValue={offset}
                    onChange={editSearchParams}
                  ></input>
                  <br />
                  <br />
                  <p> Click "Load Magic Eden Data" to refresh the results: </p>
                  <p className="card-text">
                    <a href="#" className="btn btn-primary" onClick={refetch}>
                      Load Magic Eden Data
                    </a>
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Search by Symbol</h5>
                  <p className="card-text">
                    {" "}
                    Find details by typing the Collection Symbol as it is listed
                    in Magic Eden. (Advanced users only.){" "}
                  </p>
                  <input
                    id="collSymbol"
                    defaultValue={defaultSymbol}
                    onChange={findSymbol}
                  ></input>
                  <br />
                  <br />
                  <p className="card-text">
                    <a href={searchURL} className="btn btn-primary">
                      Load Collection
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default MagicEdenCollections;
