import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const KingsAlphaChallenge = () => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <input
        autoFocus
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <button type="button" className="btn btn-danger" onClick={onClear}>
        X
      </button>
    </>
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  /**
  const {
    data: tokenData,
    isLoading: tokenIsLoading,
    isError: tokenIsError,
  } = useQuery(["tokens"], async () => {
    const res = await axios.get(
      "https://tranquil-mesa-04927.herokuapp.com/https://api.raydium.io/v2/main/pairs"
    );
    return res.data;
  });
   */

  // let array = tokenData?.sort((a, b) => (a.name.trim() > b.name.trim()) ? 1 : ((b.name.trim() > a.name.trim()) ? -1 : 0));

  function filterTokens(input) {
    return input.name.toLowerCase().includes(filterText.toLowerCase());
  }

  /**
  if (tokenIsError) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Error Warning</h5>

          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
  }

  if (tokenIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Collection ROI Stats...</h5>

          <div
            className="alert alert-primary alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-star me-1"></i>
            Thanks for your patience—loading Raydium token price information!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else
   */
    return (
      <div>
        <LoadROIdataTable
          //tokenData={tokenData}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          filterFunction={filterTokens}
        />
      </div>
    );
};

export const LoadROIdataTable = (props) => {

  function StakingIcon(props) {
    const blurb = props.row.stakingBlurb;
    if (blurb) {
      return (
        <>
          <div style={{ display: "inline-block" }} title={blurb}>
            <div className="icon">
              <i
                className="bi bi-cloud-arrow-up-fill"
                data-bs-toggle="modal"
                data-bs-target={"#addFavoriteModal" + props.row.id}
              ></i>
              <div className="label"></div>
            </div>
          </div>
          <div className="card-text" style={{ color: "black" }}>
            <div
              className="modal fade"
              id={"addFavoriteModal" + props.row.id}
              tabIndex="-1"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Staking Level Details: <br />
                      {props.row.name}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <StakingModalBlurb row={props.row} />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <a
                      href={`/collection?symbol=${props.row.symbol}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button" className="btn btn-primary">
                        View Collection Details
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return "";
  }

  function StakingModalBlurb(props) {
    const blurbHtml = props.row.stakingBlurbHtml;
    if (blurbHtml) {
      return <>{blurbHtml}</>;
    }
    return "";
  }

  const {
    data: birdeyeSolPriceData,
    isLoading: birdeyeSolPriceIsLoading,
    isError: birdeyeSolPriceIsError,
  } = useQuery(["birdeyeSolPrice"], async () => {
    const headers = {
      "X-API-KEY": "53bf2be7099141cf9500e6f2844f0882",
    };
  
    const res = await axios.get(
      "https://public-api.birdeye.so/defi/price?address=So11111111111111111111111111111111111111112",
      { headers }
    );
    return res.data;
  });

  if (birdeyeSolPriceIsError) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Error Warning</h5>

          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
  }

  if (birdeyeSolPriceIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Collection ROI Stats...</h5>

          <div
            className="alert alert-primary alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-star me-1"></i>
            Thanks for your patience—loading King's Alpha Challenge data!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {

    return (
      <div>
        <section className="section">
          <div className="row align-items-top">
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-12">
                    <div className="card mb-9" style={{ padding: "2rem" }}>
                      <center>
                        <h1>
                          <b>King's Alpha Challenge</b>
                        </h1>
                        <h4>
                          a.k.a. Ape Calls{" "}
                          <a
                            href="https://discord.com/invite/9ES7UszWTX"
                            target="_blank"
                          >
                            (See Discord for Details)
                          </a>
                        </h4>
                      </center>
                    </div>
                    <div className="card-body">
                      <center>
                        <p className="card-text">
                          Compete in Ape King's Weekly Alpha Challenge for a
                          chance to win prizes!
                          <br />
                          <b>
                            <i>Weekly Challenge starts Mondays at 8am CST.</i>
                          </b>
                        </p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 d-flex align-items-stretch p-0">
              <div className="card mb-3 w-100">
                <div className="row g-0">
                  <div className="col-md-4">
                  <br />
                    <img height='230' src="https://alternative.me/crypto/fear-and-greed-index.png" alt="Latest Crypto Fear & Greed Index" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h4 className="solPriceHeader">
                        <br />
                        <br />
                        <br />
                        Price of SOL:{" "}
                        <b>${1 * birdeyeSolPriceData.data.value.toFixed(2)}</b>
                      </h4>
                      <br />
                      <p
                        className="card-text"
                        style={{ fontSize: "small", textAlign: "right" }}
                      >
                        <i>Live price data from BirdEye.so</i>
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="row align-items-top" style={{ padding: "1rem" }}>
            <div className="col-lg-12">
              <div className="card mb-12" style={{ padding: "2rem" }}>
                <iframe
                  style={{marginLeft: '2%'}}
                  src="https://docs.google.com/spreadsheets/d/1WObd_8HSCLlLuQJWJlDa06obm3q-ObZnV4or2hOAs70/gviz/tq?tqx=out:html&tq&gid=0"
                  width="100%"
                  height="400"
                ></iframe>
                <iframe
                  style={{marginLeft: '2%'}}
                  src="https://docs.google.com/spreadsheets/d/1pTYomQIIJkpC4MtKt4KP3D0BKE-Hou2ze2zJ2upZro8/gviz/tq?tqx=out:html&tq&gid=0"
                  width="50%"
                  height="110"
                ></iframe>
                <div style={{marginLeft: '2%'}}>
                  <h4>Challenge Rules:</h4>
                  <ul>
                    <li>
                      Challenges start every Monday at 8am CST. Winners will be
                      chosen prior to the start of the next round.
                    </li>
                    <li>
                      Weekly Prizes for 1st, 2nd & 3rd Place Winners. Highest %
                      ROI wins top honors!
                    </li>
                    <li>
                      You must own a Pixel Ape and be a DAO Member to enter and
                      win.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
      </div>
    );
  }
};

export default KingsAlphaChallenge;
