import React from "react";
import { useLocalStorage } from "../helpers/useLocalStorage";

const UserFavorites = () => {
  const [favList, setFavList] = useLocalStorage("favList2", []);

  const handleFavorite = (symbol) => {
    setFavList([...favList, symbol]);
  };

  const deleteFavorite = (id) => {
    setFavList(favList.filter((fav) => fav.id !== id));
  };

  const deleteAllFavs = () => {
    setFavList([]);
  };

  if (favList.length == 0)
    return (
      <div style={{ padding: "1rem" }}>
        <h3>Add collections to your favorites and they will show up here!</h3>
        <br />
        <p className="card-text">
          <a href="/collections" className="btn btn-primary">
            Browse Collections
          </a>
        </p>
      </div>
    );
  else
    return (
      <div style={{ padding: "1rem" }}>
        <h3>You have saved {favList.length} favorite collection(s)!</h3>
        <br />
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">My Favorites</h5>
              <ul className="list-group">
                {favList.map((_, index) => (
                  <li className="list-group-item">
                    <b>
                      <a href={"/collection?symbol=" + favList[index]}>
                        {favList[index]}
                      </a>
                    </b>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <p className="card-text">
          <a href="/collections" className="btn btn-primary">
            Browse Collections
          </a>
        </p>
        <br />
        <br />
        <button
          type="button"
          className="btn btn-danger"
          onClick={deleteAllFavs}
        >
          Remove All Favorites
        </button>
      </div>
    );
};

export default UserFavorites;
