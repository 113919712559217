import React from "react";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import DataTable, { defaultThemes } from "react-data-table-component";

export const RaydiumTokens = () => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <input
        autoFocus
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <button type="button" className="btn btn-danger" onClick={onClear}>
        X
      </button>
    </>
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const {
    data: tokenData,
    isLoading: tokenIsLoading,
    isError: tokenIsError,
    refetch,
  } = useQuery(["tokens"], () => {
    return Axios.get(
      "https://tranquil-mesa-04927.herokuapp.com/https://api.raydium.io/v2/main/pairs"
    ).then((res) => res.data);
  });

  function filterTokens(input) {
    // filter out if name starts with "unknown-" or if there is near-zero liquidity
    return (
      !input.name.startsWith("unknown") &&
      input.liquidity > 100 &&
      input.tokenAmountPc > 100 &&
      input.name.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  if (tokenIsError) {
    return (
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Error Warning</h5>

          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i class="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
  }

  if (tokenIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Token Pairs...</h5>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading Token Pairs...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const filteredTokens = tokenData.filter(filterTokens);
    const columns = [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Price ($ or SOL)",
        selector: (row) => 1 * row.price.toFixed(4),
        sortable: true,
      },
      {
        name: "Total Liquidity ($)",
        selector: (row) => 1 * row.liquidity.toFixed(2),
        sortable: true,
      },
      {
        name: "Liquidity USDC ($)",
        selector: (row) => 1 * row.tokenAmountPc.toFixed(2),
        sortable: true,
      },
    ];

    const customStyles = {
      header: {
        style: {
          minHeight: "56px",
        },
      },
      headRow: {
        style: {
          borderTopStyle: "solid",
          borderTopWidth: "1px",
          borderTopColor: defaultThemes.default.divider.default,
        },
      },
      headCells: {
        style: {
          "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
          },
        },
      },
      cells: {
        style: {
          "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
          },
        },
      },
    };

    return (
      <DataTable
        title="Raydium Token Pairs"
        columns={columns}
        data={filteredTokens}
        customStyles={customStyles}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        // selectableRows
        persistTableHead
        defaultSortFieldId={3}
        defaultSortAsc={false}
      />
    );
  }
};

export default RaydiumTokens;
