import React from "react";
import UserFavorites from "../components/UserFavorites";

const MyFavorites = () => {
  return (
    <div style={{ padding: "1rem" }}>
      <UserFavorites />
    </div>
  );
};

export default MyFavorites;
