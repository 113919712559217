import MagicEdenCollections from "../components/AllCollections";
const Collections = () => {
  return (
    <div style={{ padding: "1rem" }}>
      <h3>Browse NFT Collections</h3>
      <MagicEdenCollections />
    </div>
  );
};

export default Collections;
