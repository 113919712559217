import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import DataTable from "react-data-table-component";
import { useState } from "react";
import React from "react";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import { useLocalStorage } from "../helpers/useLocalStorage";

function useSearchQuery() {
  return new URLSearchParams(useLocation().search);
}

const Collection = () => {
  const [favList, setFavList] = useLocalStorage("favList2", []);

  const [traitType, setTraitType] = useState("Special Trait");
  const [traitValue, setTraitValue] = useState("OG");

  const handleFavorite = (symbol) => {
    //console.log(!favList.find(e => e == symbol));
    if (!favList.find((e) => e == symbol)) setFavList([...favList, symbol]);
  };

  const deleteFavorite = (symbol) => {
    setFavList(favList.filter((fav) => fav !== symbol));
  };

  let query = useSearchQuery();

  let url =
    "https://tranquil-mesa-04927.herokuapp.com/https://api-mainnet.magiceden.dev/v2/collections/" +
    query.get("symbol");
  const {
    data: meCollData,
    isLoading: meCollIsLoading,
    isError: meCollIsError,
  } = useQuery(["collStats"], async () => {
    const res = await Axios.get(url);
    return res.data;
  });

  let statsUrl =
    "https://tranquil-mesa-04927.herokuapp.com/https://api-mainnet.magiceden.dev/v2/collections/" +
    query.get("symbol") +
    "/stats";
  const {
    data: statsData,
    isLoading: statsIsLoading,
    isError: statsIsError,
  } = useQuery(["collStatsAPI"], async () => {
    const res = await Axios.get(statsUrl);
    return res.data;
  });

  let x = 0;
  let listingsUrl =
    "https://tranquil-mesa-04927.herokuapp.com/https://api-mainnet.magiceden.dev/v2/collections/" +
    query.get("symbol") +
    "/listings?offset=" +
    x * 20 +
    "&limit=20";
  const {
    data: listingsData,
    isLoading: listingsIsLoading,
    isError: listingsIsError,
  } = useQuery(["listingsAPI"], async () => {
    const res = await Axios.get(listingsUrl);
    return res.data;
  });

  if (meCollIsError || statsIsError || listingsIsError) {
    return (
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Error Warning</h5>

          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i class="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
  }

  if (meCollIsLoading || statsIsLoading || listingsIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Collection Details...</h5>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    //console.log(listingsData);
    if (isNaN(statsData.floorPrice)) statsData.floorPrice = 0;
    if (isNaN(statsData.listedCount)) statsData.listedCount = 0;
    if (isNaN(statsData.avgPrice24hr)) statsData.avgPrice24hr = 0;
    if (isNaN(statsData.volumeAll)) statsData.volumeAll = 0;

    const ExpandedComponent = ({ data }) => (
      <pre>{JSON.stringify(data, null, 2)}</pre>
    );

    const columns = [
      {
        name: "Price (SOL)",
        selector: (row) => row.price,
        sortable: true,
      },
      {
        name: "ME Rarity Rank",
        selector: (row) => row.rarity.merarity?.rank,
        sortable: true,
      },
      {
        name: "Image",
        selector: (row) => (
          <img
            src={row.extra?.img}
            className="img-thumbnail"
            style={{ width: "150px" }}
          ></img>
        ),
        sortable: false,
      },
    ];

    //  Internally, customStyles will deep merges your customStyles with the default styling.
    const customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    const ListingTable = ({
      expandableRows,
      expandOnRowClicked,
      expandOnRowDoubleClicked,
      expandableRowsHideExpander,
    }) => (
      <DataTable
        title="Most Recent Listings"
        customStyles={customStyles}
        columns={columns}
        data={listingsData}
        expandableRows={expandableRows}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={expandOnRowClicked}
        expandOnRowDoubleClicked={expandOnRowDoubleClicked}
        expandableRowsHideExpander={expandableRowsHideExpander}
        pagination
      />
    );

    return (
      <div style={{ padding: "1rem" }}>
        <h3>Collection Details</h3>
        <div>
          <section className="section">
            <div className="col-lg-12">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={meCollData?.image}
                      className="img-thumbnail"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{meCollData?.name}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <i>{meCollData?.symbol}</i>
                      </h6>
                      <br />
                      <p className="card-text">{meCollData?.description}</p>
                      <br />
                      <a
                        href={
                          "https://magiceden.io/marketplace/" +
                          meCollData?.symbol
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="card-link"
                      >
                        Magic Eden
                      </a>
                      <a
                        href={meCollData?.discord}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="card-link"
                      >
                        Discord
                      </a>
                      <a
                        href={meCollData?.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="card-link"
                      >
                        Twitter
                      </a>
                      <a
                        href={meCollData?.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="card-link"
                      >
                        Website
                      </a>
                      <br />
                      <br />
                      <button
                        type="button"
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#addFavoriteModal"
                        onClick={() => handleFavorite(meCollData?.symbol)}
                      >
                        Add Favorite
                      </button>{" "}
                      &ensp;
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#removeFavoriteModal"
                        onClick={() => deleteFavorite(meCollData?.symbol)}
                      >
                        Remove Favorite
                      </button>{" "}
                      &ensp;
                      <div
                        className="modal fade"
                        id="addFavoriteModal"
                        tabIndex="-1"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">
                                Added {meCollData?.name} to your favorites!
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              You can now view {meCollData?.name} in your
                              favorites.
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <a href="/myfavorites">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  href="/myfavorites"
                                >
                                  View My Favorites
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="removeFavoriteModal"
                        tabIndex="-1"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">
                                Removed {meCollData?.name} from your favorites!
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              You can view your saved collections or keep
                              browsing.
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <a href="/myfavorites">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  href="/myfavorites"
                                >
                                  View My Favorites
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <p className="card-text">
                        <a href="/collections" className="btn btn-secondary">
                          Back to Browse Collections
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-top">
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Floor Price <span>| Today</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h5>{statsData?.floorPrice / 1e9} SOL</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Listed Count <span>| Today</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-cart"></i>
                      </div>
                      <div className="ps-3">
                        <h5>{(statsData?.listedCount).toFixed(0)}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Average Price <span>| Today</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-currency-dollar"></i>
                      </div>
                      <div className="ps-3">
                        <h5>
                          {(statsData?.avgPrice24hr / 1e9).toFixed(2)} SOL
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Total Volume <span>| All</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-currency-dollar"></i>
                      </div>
                      <div className="ps-3">
                        <h5>{(statsData?.volumeAll / 1e9).toFixed(2)} SOL</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingTable expandableRows />
      </div>
    );
  }
};

export default Collection;
